import { useColorModeValue } from '@chakra-ui/color-mode'
import styles from "./Logo.module.css";

const Logo = () => {
    return (
        <>
            <svg
                version="1.1"
                id="logo"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="37 27.37 69.86 34.13"
                xmlSpace="preserve"
                className={ `${styles.logo} ${useColorModeValue(styles.lightMode, styles.darkMode)}`}
            >
                <g>
                    <g>
                        <g>
                            <path d="M68.455,42.135c-2.19,2.057-4.192,4.157-6.198,6.306c-0.501,0.538-1.003,1.078-1.509,1.621
                        c-0.253,0.272-0.506,0.544-0.761,0.818c-0.199,0.194-0.383,0.373-0.573,0.568c-0.79,0.721-1.726,1.317-2.787,1.705
                        c-2.087,0.757-4.331,0.715-6.254-0.046c-0.965-0.373-1.859-0.905-2.66-1.581c-0.191-0.181-0.385-0.36-0.583-0.536
                        c-0.175-0.2-0.348-0.402-0.529-0.599c-0.096-0.094-0.169-0.207-0.246-0.316l-0.235-0.327c-0.173-0.208-0.287-0.455-0.428-0.688
                        c-1.103-1.938-1.407-4.224-0.97-6.298c0.449-2.079,1.64-3.889,3.261-5.192c1.631-1.261,3.678-1.939,5.775-1.89
                        c2.098,0.035,4.185,0.844,5.821,2.327c0.035,0.031,0.078,0.069,0.129,0.113c0.025,0.022,0.053,0.046,0.082,0.072
                        c0.031,0.028,0.055,0.045,0.105,0.097c0.179,0.18,0.398,0.401,0.656,0.661c0.515,0.522,1.183,1.199,1.988,2.014
                        c1.605,1.632,3.751,3.813,6.289,6.393l6.321,6.426l0.542-9.065l0-0.001l-6.864,2.639c2.366,2.406,5.072,5.158,7.998,8.132
                        c0.183,0.186,0.367,0.373,0.551,0.56l0.277,0.282l0.139,0.141l0.191,0.178c0.52,0.47,1.017,0.974,1.606,1.383
                        c0.287,0.214,0.574,0.429,0.868,0.637c0.309,0.187,0.619,0.374,0.93,0.562l0.471,0.277l0.493,0.239
                        c0.332,0.155,0.665,0.31,0.999,0.466c2.73,1.1,5.817,1.568,8.861,1.113c0.765-0.082,1.512-0.282,2.265-0.465
                        c0.373-0.103,0.737-0.24,1.106-0.364l0.551-0.194c0.18-0.074,0.357-0.157,0.535-0.237c0.355-0.163,0.713-0.322,1.062-0.497
                        l1.017-0.59c0.345-0.19,0.661-0.422,0.978-0.654c0.314-0.235,0.644-0.454,0.94-0.711c0.585-0.521,1.189-1.028,1.701-1.627
                        l0.396-0.437c0.132-0.146,0.264-0.291,0.379-0.453l0.707-0.952c0.877-1.311,1.641-2.722,2.118-4.232l0.192-0.561l0.147-0.574
                        c0.092-0.384,0.2-0.765,0.268-1.152l0.174-1.168l0.04-0.292c0.009-0.098,0.013-0.196,0.019-0.294l0.032-0.588
                        c0.065-0.784,0.002-1.567-0.035-2.348c-0.04-0.388-0.1-0.775-0.154-1.161c-0.03-0.193-0.054-0.386-0.09-0.577l-0.133-0.569
                        c-0.321-1.525-0.919-2.973-1.631-4.331c-0.195-0.331-0.389-0.662-0.583-0.991l-0.149-0.245l-0.166-0.232l-0.335-0.462
                        l-0.338-0.458l-0.17-0.227l-0.187-0.212c-0.252-0.28-0.504-0.56-0.754-0.838c-0.244-0.286-0.533-0.524-0.805-0.779
                        c-0.279-0.247-0.545-0.506-0.838-0.734c-0.299-0.221-0.596-0.442-0.893-0.661c-0.151-0.106-0.295-0.222-0.452-0.318l-0.473-0.284
                        c-0.316-0.187-0.63-0.375-0.948-0.552c-0.33-0.155-0.659-0.309-0.986-0.463l-0.493-0.224l-0.506-0.183
                        c-0.339-0.117-0.676-0.234-1.012-0.35c-2.728-0.807-5.533-0.897-8.098-0.426c-2.569,0.48-4.884,1.554-6.767,2.912
                        c-0.467,0.347-0.933,0.679-1.335,1.069c-0.206,0.189-0.41,0.377-0.612,0.562l-0.15,0.14l-0.074,0.07l-0.055,0.056l-0.213,0.217
                        c-0.564,0.577-1.106,1.131-1.623,1.661c-4.132,4.243-6.652,6.962-6.562,7.194c0.022,0.063,0.688-0.531,1.683-1.466
                        c0.996-0.935,2.32-2.21,3.66-3.509c1.345-1.307,2.708-2.633,3.769-3.665c0.35-0.31,0.674-0.599,0.967-0.859
                        c0.144-0.133,0.294-0.243,0.435-0.344c0.141-0.102,0.272-0.197,0.393-0.285c0.244-0.173,0.446-0.316,0.599-0.424
                        c0.153-0.108,0.27-0.162,0.319-0.193c1.52-0.901,3.683-1.818,6.358-2.138c1.331-0.185,2.788-0.162,4.301,0.045
                        c1.517,0.194,3.082,0.682,4.607,1.4c3.068,1.425,5.869,4.076,7.513,7.562c0.818,1.739,1.325,3.673,1.447,5.663
                        c0.124,1.989-0.136,4.035-0.795,5.973c-0.655,1.938-1.715,3.764-3.092,5.306c-1.371,1.542-3.06,2.818-4.937,3.7
                        c-1.874,0.885-3.92,1.376-5.961,1.452c-2.041,0.078-4.077-0.256-5.947-0.958c-1.871-0.698-3.571-1.767-4.977-3.071
                        c-0.176-0.163-0.352-0.325-0.527-0.488l-0.423-0.431c-0.281-0.286-0.56-0.57-0.838-0.853c-0.556-0.565-1.105-1.123-1.646-1.673
                        c-2.169-2.197-4.215-4.268-6.077-6.154l3.912-1.504l0,0.001l-0.287,5.143l-3.625-3.639c-2.216-2.224-4.169-4.185-5.772-5.794
                        c-0.806-0.8-1.525-1.512-2.144-2.126c-0.612-0.597-1.175-1.171-1.506-1.428c-0.713-0.656-1.54-1.146-2.379-1.565l-0.645-0.282
                        c-0.213-0.096-0.444-0.147-0.663-0.225c-0.436-0.169-0.902-0.227-1.349-0.341c-1.824-0.285-3.699-0.142-5.365,0.509l-0.313,0.116
                        l-0.157,0.057c-0.051,0.023-0.1,0.05-0.15,0.074l-0.597,0.294l-0.15,0.072l-0.141,0.088l-0.281,0.176l-0.282,0.174
                        c-0.095,0.055-0.188,0.114-0.272,0.186c-0.344,0.277-0.711,0.519-1.015,0.841c-0.157,0.154-0.326,0.295-0.475,0.455l-0.428,0.499
                        c-0.071,0.083-0.146,0.163-0.215,0.247l-0.185,0.269l-0.376,0.531l-0.317,0.567l-0.161,0.281
                        c-0.05,0.095-0.087,0.198-0.131,0.296l-0.261,0.591c-0.075,0.202-0.132,0.41-0.202,0.613l-0.102,0.306
                        c-0.033,0.102-0.047,0.21-0.072,0.314l-0.141,0.628c-0.583,3.392,0.637,7.021,3.306,9.209c0.309,0.3,0.677,0.52,1.021,0.771
                        c0.084,0.066,0.177,0.119,0.272,0.168l0.28,0.155c0.189,0.098,0.363,0.226,0.566,0.297l0.587,0.256
                        c0.095,0.049,0.199,0.078,0.301,0.109l0.306,0.097c0.4,0.156,0.83,0.212,1.246,0.316c1.696,0.272,3.49,0.137,5.11-0.518
                        c1.072-0.417,2.014-1.041,2.806-1.789c0.194-0.198,0.387-0.394,0.579-0.591c0.249-0.273,0.498-0.546,0.745-0.818
                        c0.496-0.544,0.989-1.085,1.481-1.625c1.973-2.155,3.952-4.268,6.137-6.335c0.097-0.09,0.146-0.19,0.126-0.231
                        C68.652,41.993,68.557,42.028,68.455,42.135z"/>
                        </g>
                        <g>
                            <path d="M69.631,43.31c-2.19,2.057-4.191,4.157-6.198,6.306c-0.501,0.538-1.003,1.078-1.509,1.621
                    c-0.253,0.272-0.506,0.544-0.761,0.818c-0.225,0.22-0.451,0.441-0.677,0.664c-0.938,0.847-2.052,1.546-3.308,2.002
                    c-2.471,0.891-5.108,0.835-7.385-0.057c-1.142-0.439-2.203-1.068-3.149-1.866c-0.228-0.21-0.457-0.42-0.688-0.632
                    c-0.208-0.234-0.416-0.468-0.626-0.704c-0.112-0.113-0.2-0.244-0.291-0.373l-0.279-0.386c-0.202-0.248-0.342-0.538-0.509-0.813
                    c-1.31-2.296-1.674-4.995-1.162-7.449c0.522-2.462,1.931-4.626,3.846-6.163c1.931-1.505,4.368-2.318,6.854-2.261
                    c2.486,0.04,4.979,1.003,6.916,2.767c0.085,0.077,0.198,0.171,0.365,0.329c0.179,0.18,0.398,0.401,0.656,0.661
                    c0.515,0.522,1.183,1.199,1.988,2.014c1.605,1.632,3.751,3.814,6.289,6.393l3.709,3.771l0.318-5.319v-0.001l-4.027,1.549
                    c2.366,2.406,5.072,5.158,7.997,8.132c0.183,0.186,0.367,0.373,0.551,0.56l0.277,0.282l0.139,0.141l0.173,0.161
                    c0.47,0.422,0.916,0.881,1.449,1.249c0.259,0.192,0.517,0.387,0.781,0.576c0.278,0.168,0.558,0.337,0.838,0.506l0.424,0.251
                    l0.445,0.215c0.299,0.14,0.599,0.281,0.9,0.422c2.461,0.99,5.247,1.419,7.994,1.007c0.691-0.071,1.364-0.255,2.044-0.417
                    c0.337-0.092,0.665-0.218,0.997-0.329l0.497-0.174c0.162-0.067,0.321-0.143,0.482-0.214c0.32-0.147,0.643-0.29,0.959-0.447
                    l0.917-0.533c0.312-0.17,0.596-0.381,0.882-0.59c0.283-0.213,0.582-0.408,0.849-0.64c0.527-0.473,1.076-0.927,1.537-1.47
                    l0.359-0.395c0.12-0.131,0.24-0.262,0.343-0.408l0.638-0.859c0.79-1.184,1.483-2.454,1.912-3.817l0.174-0.506l0.132-0.518
                    c0.082-0.347,0.182-0.69,0.243-1.04l0.157-1.055l0.037-0.264c0.008-0.088,0.011-0.177,0.017-0.265l0.029-0.531
                    c0.061-0.708,0.001-1.415-0.029-2.12c-0.037-0.351-0.092-0.7-0.14-1.049c-0.026-0.174-0.047-0.349-0.08-0.522l-0.121-0.513
                    c-0.287-1.378-0.83-2.683-1.47-3.909c-0.176-0.299-0.351-0.597-0.526-0.895l-0.134-0.221l-0.151-0.209l-0.303-0.417l-0.305-0.414
                    l-0.153-0.206l-0.17-0.191c-0.228-0.253-0.455-0.506-0.682-0.758c-0.219-0.26-0.483-0.473-0.728-0.703
                    c-0.253-0.223-0.492-0.459-0.757-0.665c-0.27-0.2-0.539-0.398-0.807-0.597c-0.136-0.096-0.265-0.201-0.407-0.288l-0.427-0.256
                    c-0.285-0.168-0.568-0.339-0.855-0.499c-0.298-0.139-0.595-0.278-0.89-0.417L95.6,30.159l-0.458-0.165
                    c-0.306-0.106-0.61-0.212-0.913-0.317c-2.463-0.727-4.998-0.809-7.315-0.384c-2.321,0.434-4.408,1.405-6.106,2.631
                    c-0.42,0.314-0.843,0.611-1.205,0.966c-0.186,0.171-0.37,0.34-0.553,0.508c-0.085,0.081-0.193,0.176-0.258,0.245L78.58,33.86
                    c-0.564,0.577-1.106,1.131-1.623,1.661c-4.132,4.243-6.652,6.962-6.561,7.194c0.022,0.063,0.688-0.531,1.684-1.466
                    c0.996-0.935,2.32-2.21,3.66-3.509c1.341-1.304,2.702-2.627,3.76-3.655c0.315-0.279,0.607-0.537,0.87-0.771
                    c0.129-0.12,0.264-0.218,0.391-0.308c0.127-0.091,0.245-0.176,0.354-0.255c0.219-0.155,0.4-0.284,0.537-0.381
                    c0.137-0.097,0.243-0.144,0.287-0.172c1.367-0.803,3.309-1.617,5.709-1.896c1.194-0.165,2.501-0.137,3.855,0.051
                    c1.36,0.174,2.758,0.619,4.121,1.264c2.74,1.275,5.244,3.663,6.706,6.776c0.728,1.554,1.178,3.282,1.284,5.061
                    c0.108,1.778-0.128,3.605-0.719,5.333c-1.165,3.469-3.819,6.462-7.172,8.024c-1.67,0.786-3.495,1.222-5.314,1.287
                    c-1.82,0.068-3.635-0.233-5.298-0.859c-1.666-0.624-3.175-1.575-4.427-2.739l-0.47-0.434l-0.423-0.43
                    c-0.281-0.286-0.56-0.57-0.838-0.853c-0.556-0.565-1.105-1.123-1.646-1.673c-2.169-2.197-4.215-4.268-6.077-6.154l1.075-0.413
                    l0,0.001l-0.079,1.414l-0.996-1c-2.216-2.224-4.169-4.185-5.772-5.794c-0.806-0.8-1.525-1.512-2.144-2.126
                    c-0.621-0.605-1.146-1.145-1.561-1.481c-0.824-0.758-1.779-1.336-2.754-1.822c-0.25-0.11-0.5-0.22-0.749-0.329
                    C58,33.294,57.733,33.23,57.479,33.14c-0.507-0.193-1.045-0.27-1.565-0.399c-2.116-0.342-4.289-0.178-6.233,0.57l-0.365,0.135
                    l-0.182,0.067c-0.059,0.026-0.117,0.057-0.175,0.085l-0.697,0.34l-0.174,0.084l-0.165,0.101l-0.328,0.203l-0.328,0.202
                    c-0.111,0.065-0.219,0.134-0.318,0.216c-0.404,0.317-0.828,0.605-1.185,0.974c-0.183,0.178-0.378,0.343-0.553,0.529l-0.501,0.577
                    c-0.083,0.096-0.169,0.19-0.249,0.288l-0.219,0.311l-0.439,0.619l-0.374,0.659l-0.187,0.328c-0.059,0.111-0.103,0.23-0.156,0.344
                    l-0.305,0.69c-0.088,0.235-0.158,0.477-0.239,0.714l-0.118,0.357c-0.038,0.119-0.057,0.244-0.087,0.366l-0.165,0.733
                    c-0.041,0.247-0.063,0.497-0.097,0.745l-0.047,0.372c-0.012,0.124-0.009,0.25-0.014,0.375l-0.022,0.749
                    c0.011,0.249,0.033,0.499,0.048,0.748l0.024,0.373c0.016,0.124,0.04,0.246,0.059,0.369l0.12,0.738l0.191,0.723l0.094,0.361
                    l0.131,0.35l0.259,0.7l0.328,0.67l0.162,0.336l0.197,0.317l0.391,0.635l0.454,0.594l0.226,0.297
                    c0.077,0.098,0.17,0.183,0.253,0.276l0.513,0.546c0.174,0.179,0.375,0.331,0.56,0.498c0.362,0.347,0.788,0.613,1.193,0.906
                    c0.099,0.077,0.207,0.139,0.318,0.198l0.327,0.184c0.221,0.117,0.427,0.262,0.663,0.35l0.688,0.303
                    c0.112,0.056,0.233,0.092,0.353,0.13l0.358,0.116c0.47,0.18,0.972,0.255,1.461,0.374c1.987,0.331,4.089,0.176,5.998-0.583
                    c1.263-0.486,2.38-1.213,3.319-2.085c0.228-0.229,0.454-0.456,0.68-0.683c0.249-0.273,0.498-0.546,0.745-0.818
                    c0.496-0.544,0.989-1.085,1.481-1.625c1.973-2.155,3.952-4.268,6.137-6.335c0.097-0.091,0.146-0.19,0.126-0.231
                    C69.828,43.168,69.732,43.204,69.631,43.31z"/>
                        </g>
                        <g>
                            <path d="M70.807,44.486c-2.19,2.057-4.192,4.157-6.198,6.306c-0.501,0.538-1.003,1.078-1.509,1.621
                    c-0.253,0.272-0.506,0.544-0.761,0.818c-0.259,0.252-0.52,0.505-0.782,0.759c-1.087,0.972-2.379,1.775-3.83,2.3
                    c-2.855,1.024-5.886,0.955-8.517-0.068c-1.319-0.505-2.546-1.231-3.638-2.151c-0.263-0.241-0.527-0.484-0.792-0.727
                    c-0.24-0.268-0.48-0.538-0.722-0.809c-0.127-0.131-0.23-0.281-0.337-0.429c-0.107-0.148-0.215-0.296-0.322-0.445
                    c-0.23-0.288-0.398-0.62-0.589-0.939c-1.517-2.654-1.941-5.766-1.353-8.6c0.595-2.845,2.222-5.362,4.43-7.135
                    c2.23-1.75,5.058-2.698,7.933-2.632c2.874,0.045,5.773,1.163,8.011,3.207c0.096,0.087,0.234,0.205,0.414,0.376
                    c0.179,0.18,0.398,0.401,0.656,0.661c0.515,0.522,1.183,1.199,1.988,2.014c1.605,1.632,3.751,3.813,6.289,6.393l1.097,1.115
                    l0.094-1.573v-0.001l-1.191,0.458c2.366,2.406,5.072,5.158,7.997,8.132c0.373,0.372,0.72,0.746,1.123,1.127
                    c0.421,0.374,0.816,0.789,1.291,1.114c0.231,0.171,0.462,0.343,0.695,0.515c0.248,0.149,0.497,0.299,0.747,0.45l0.376,0.225
                    l0.397,0.19c0.266,0.125,0.533,0.251,0.8,0.378c2.192,0.88,4.676,1.27,7.127,0.901c0.617-0.06,1.216-0.228,1.823-0.37
                    c0.3-0.082,0.592-0.196,0.889-0.293l0.444-0.153c0.145-0.06,0.286-0.128,0.429-0.192c0.285-0.132,0.573-0.257,0.855-0.396
                    l0.817-0.475c0.279-0.149,0.532-0.339,0.787-0.525c0.252-0.19,0.52-0.361,0.758-0.57c0.469-0.424,0.963-0.826,1.372-1.313
                    l0.321-0.352c0.108-0.117,0.216-0.233,0.306-0.364l0.569-0.766c0.702-1.057,1.326-2.185,1.706-3.403l0.157-0.451l0.116-0.463
                    c0.072-0.309,0.164-0.615,0.218-0.927l0.14-0.941l0.034-0.235c0.007-0.079,0.01-0.158,0.014-0.237l0.025-0.474
                    c0.058-0.632-0.001-1.264-0.024-1.893c-0.033-0.313-0.083-0.624-0.125-0.936c-0.023-0.155-0.041-0.312-0.07-0.466l-0.109-0.458
                    c-0.253-1.231-0.742-2.393-1.31-3.488c-0.157-0.267-0.313-0.533-0.469-0.798l-0.118-0.198l-0.135-0.186l-0.271-0.372l-0.271-0.37
                    l-0.136-0.184l-0.152-0.17c-0.204-0.226-0.407-0.452-0.609-0.677c-0.194-0.234-0.432-0.422-0.651-0.628
                    c-0.226-0.199-0.439-0.412-0.675-0.595c-0.241-0.178-0.481-0.355-0.72-0.532c-0.121-0.086-0.236-0.181-0.363-0.258L96.926,32.7
                    c-0.255-0.15-0.507-0.303-0.762-0.447c-0.266-0.124-0.531-0.248-0.795-0.371L94.974,31.7l-0.409-0.146
                    c-0.273-0.095-0.544-0.19-0.814-0.284c-2.199-0.648-4.464-0.72-6.532-0.342c-2.072,0.388-3.931,1.256-5.446,2.351
                    c-0.374,0.281-0.754,0.544-1.075,0.863c-0.326,0.304-0.677,0.609-0.943,0.894c-0.564,0.577-1.106,1.131-1.623,1.661
                    C74,40.94,71.48,43.658,71.571,43.891c0.022,0.063,0.688-0.531,1.684-1.466c0.996-0.935,2.32-2.21,3.66-3.509
                    c1.338-1.301,2.695-2.62,3.75-3.646c0.28-0.247,0.539-0.476,0.773-0.683c0.113-0.108,0.234-0.193,0.348-0.273
                    c0.113-0.08,0.218-0.156,0.314-0.225c0.194-0.138,0.354-0.252,0.476-0.338c0.121-0.087,0.216-0.127,0.255-0.151
                    c1.214-0.705,2.935-1.416,5.059-1.654c1.056-0.145,2.213-0.112,3.409,0.056c1.202,0.155,2.435,0.556,3.635,1.127
                    c2.412,1.126,4.62,3.25,5.9,5.99c0.637,1.369,1.031,2.892,1.121,4.459c0.092,1.566-0.12,3.176-0.642,4.694
                    c-1.027,3.044-3.372,5.677-6.317,7.041c-1.467,0.688-3.069,1.067-4.668,1.122c-1.599,0.057-3.192-0.209-4.65-0.76
                    c-1.46-0.549-2.779-1.383-3.878-2.407l-0.414-0.38l-0.423-0.43c-0.281-0.286-0.56-0.57-0.838-0.853
                    c-0.556-0.565-1.105-1.123-1.646-1.673c-2.169-2.197-4.215-4.268-6.077-6.154l-1.631-1.652l-0.13,2.329l0,0.001l1.761-0.677
                    c-2.216-2.224-4.169-4.185-5.772-5.794c-0.806-0.799-1.525-1.512-2.144-2.126c-0.63-0.613-1.118-1.118-1.615-1.533
                    c-0.935-0.859-2.019-1.525-3.129-2.08c-0.284-0.126-0.568-0.251-0.852-0.377c-0.283-0.125-0.584-0.203-0.875-0.306
                    c-0.578-0.218-1.188-0.314-1.781-0.456c-2.407-0.399-4.88-0.213-7.1,0.631l-0.416,0.154l-0.208,0.077
                    c-0.068,0.03-0.134,0.064-0.2,0.095l-0.796,0.386l-0.198,0.097l-0.189,0.114l-0.376,0.23l-0.375,0.231
                    c-0.126,0.075-0.25,0.153-0.363,0.246c-0.463,0.358-0.946,0.69-1.355,1.107c-0.21,0.202-0.431,0.391-0.63,0.602l-0.573,0.655
                    c-0.094,0.11-0.192,0.217-0.283,0.329l-0.252,0.354l-0.501,0.707l-0.431,0.751l-0.213,0.376c-0.067,0.127-0.12,0.262-0.18,0.392
                    l-0.35,0.788c-0.102,0.268-0.185,0.544-0.277,0.815l-0.135,0.408c-0.044,0.136-0.067,0.279-0.101,0.418l-0.189,0.838
                    c-0.048,0.282-0.076,0.567-0.115,0.85l-0.054,0.425c-0.014,0.142-0.012,0.285-0.019,0.428l-0.026,0.856
                    c0.01,0.285,0.034,0.57,0.051,0.854l0.028,0.427c0.017,0.141,0.044,0.282,0.065,0.423l0.137,0.844l0.215,0.828l0.109,0.413
                    l0.147,0.402l0.296,0.802l0.372,0.77l0.187,0.384l0.223,0.365l0.448,0.729c0.168,0.23,0.344,0.455,0.516,0.683l0.26,0.34
                    c0.088,0.112,0.192,0.211,0.288,0.317l0.586,0.625c0.199,0.205,0.426,0.381,0.638,0.573c0.415,0.395,0.899,0.706,1.364,1.04
                    c0.114,0.087,0.238,0.16,0.364,0.228l0.374,0.213c0.253,0.135,0.491,0.299,0.76,0.403l0.789,0.349
                    c0.129,0.063,0.267,0.106,0.404,0.15l0.411,0.135c0.541,0.204,1.114,0.299,1.676,0.432c2.278,0.389,4.687,0.214,6.887-0.649
                    c1.454-0.555,2.746-1.385,3.831-2.381c0.261-0.26,0.521-0.518,0.781-0.776c0.249-0.273,0.498-0.546,0.745-0.818
                    c0.496-0.544,0.989-1.085,1.481-1.625c1.973-2.155,3.952-4.268,6.137-6.335c0.097-0.09,0.146-0.19,0.126-0.231
                    C71.004,44.344,70.908,44.38,70.807,44.486z"/>
                        </g>
                        <g>
                            <path d="M71.982,45.662c-2.19,2.057-4.192,4.157-6.198,6.306c-0.501,0.538-1.003,1.078-1.509,1.621
                    c-0.253,0.272-0.506,0.544-0.761,0.818c-0.294,0.284-0.589,0.569-0.886,0.855c-1.236,1.098-2.705,2.005-4.35,2.598
                    c-3.239,1.157-6.663,1.075-9.649-0.08c-2.989-1.142-5.594-3.282-7.259-6.228c-1.723-3.011-2.208-6.537-1.545-9.751
                    c0.335-1.609,0.963-3.14,1.819-4.512c0.858-1.373,1.945-2.585,3.196-3.595c2.53-1.994,5.749-3.078,9.011-3.003
                    c1.629,0.036,3.273,0.347,4.824,0.969c1.551,0.615,3.012,1.521,4.283,2.679c0.106,0.096,0.271,0.24,0.462,0.422
                    c0.179,0.18,0.398,0.401,0.656,0.661c0.515,0.522,1.183,1.199,1.987,2.014c1.605,1.632,3.751,3.814,6.289,6.394l-1.645,0.633
                    l0-0.001l0.13-2.174l1.515,1.541c2.366,2.406,5.072,5.158,7.998,8.132c0.183,0.186,0.367,0.373,0.551,0.56l0.278,0.282
                    l0.139,0.141l0.137,0.126c0.372,0.327,0.715,0.697,1.134,0.979c0.202,0.151,0.405,0.302,0.608,0.454
                    c0.218,0.131,0.436,0.262,0.656,0.394l0.329,0.199l0.348,0.166c0.233,0.111,0.467,0.222,0.701,0.333
                    c1.923,0.77,4.106,1.121,6.261,0.796c0.543-0.05,1.068-0.202,1.602-0.322c0.264-0.071,0.519-0.173,0.781-0.258l0.39-0.133
                    c0.127-0.053,0.251-0.113,0.376-0.17c0.25-0.116,0.504-0.225,0.752-0.346l0.717-0.418c0.246-0.129,0.467-0.297,0.691-0.461
                    c0.221-0.168,0.459-0.315,0.667-0.499c0.41-0.376,0.85-0.725,1.208-1.155l0.283-0.309c0.095-0.102,0.191-0.204,0.27-0.32
                    l0.501-0.673c0.615-0.929,1.168-1.917,1.5-2.988l0.14-0.395l0.101-0.407c0.063-0.272,0.146-0.54,0.194-0.815l0.124-0.828
                    l0.031-0.207c0.006-0.069,0.008-0.139,0.012-0.209l0.022-0.417c0.054-0.556-0.003-1.112-0.019-1.666
                    c-0.03-0.275-0.075-0.549-0.111-0.823c-0.021-0.137-0.035-0.275-0.06-0.41l-0.097-0.402c-0.219-1.084-0.653-2.103-1.149-3.067
                    c-0.138-0.234-0.275-0.468-0.412-0.702l-0.103-0.175l-0.12-0.163l-0.239-0.326l-0.238-0.326l-0.119-0.163l-0.135-0.149
                    c-0.179-0.199-0.358-0.398-0.537-0.596c-0.169-0.208-0.381-0.371-0.574-0.553c-0.199-0.174-0.385-0.365-0.594-0.525
                    c-0.212-0.156-0.423-0.312-0.634-0.467c-0.107-0.075-0.207-0.161-0.318-0.228l-0.336-0.199c-0.224-0.132-0.447-0.263-0.669-0.394
                    c-0.234-0.109-0.467-0.217-0.699-0.324l-0.347-0.162l-0.36-0.127c-0.24-0.084-0.478-0.168-0.716-0.251
                    c-1.934-0.568-3.929-0.631-5.749-0.299c-1.823,0.343-3.455,1.107-4.785,2.07c-0.328,0.248-0.664,0.476-0.944,0.76
                    c-0.292,0.275-0.579,0.517-0.862,0.819c-0.564,0.577-1.106,1.131-1.623,1.661c-4.132,4.243-6.652,6.962-6.562,7.194
                    c0.022,0.063,0.688-0.531,1.684-1.466c0.996-0.935,2.32-2.21,3.66-3.509c1.335-1.297,2.688-2.613,3.741-3.637
                    c0.245-0.215,0.472-0.414,0.677-0.595c0.098-0.095,0.204-0.169,0.304-0.237c0.098-0.07,0.19-0.135,0.275-0.195
                    c0.169-0.12,0.308-0.22,0.414-0.295c0.105-0.076,0.189-0.109,0.223-0.13c1.061-0.608,2.562-1.214,4.41-1.412
                    c0.919-0.124,1.925-0.087,2.964,0.062c1.045,0.136,2.111,0.493,3.149,0.99c2.084,0.977,3.995,2.837,5.093,5.204
                    c0.547,1.184,0.884,2.501,0.958,3.857c0.076,1.355-0.111,2.746-0.566,4.055c-0.453,1.309-1.172,2.532-2.111,3.573
                    c-0.939,1.044-2.086,1.9-3.349,2.485c-1.263,0.589-2.643,0.913-4.021,0.957c-1.378,0.047-2.75-0.186-4.001-0.661
                    c-1.254-0.474-2.384-1.191-3.329-2.074l-0.358-0.326l-0.422-0.429c-0.281-0.286-0.56-0.57-0.838-0.853
                    c-0.556-0.565-1.105-1.123-1.646-1.673c-2.169-2.197-4.215-4.268-6.077-6.154l-4.258-4.312l-0.339,6.079l0,0.001l4.597-1.768
                    c-2.216-2.224-4.169-4.185-5.772-5.794C67,36.012,66.281,35.3,65.662,34.686c-0.311-0.305-0.598-0.586-0.858-0.841
                    c-0.065-0.064-0.129-0.125-0.191-0.186l-0.092-0.089c-0.038-0.034-0.075-0.067-0.112-0.1c-0.148-0.131-0.287-0.254-0.418-0.369
                    c-1.046-0.961-2.258-1.715-3.503-2.338c-0.319-0.142-0.637-0.283-0.955-0.424c-0.318-0.14-0.654-0.231-0.98-0.346
                    c-0.65-0.242-1.332-0.357-1.998-0.514c-2.698-0.457-5.471-0.248-7.967,0.692l-0.468,0.173l-0.233,0.087l-0.226,0.106
                    l-0.896,0.432l-0.223,0.109l-0.213,0.127l-0.424,0.257l-0.421,0.259c-0.142,0.084-0.28,0.173-0.409,0.276
                    c-0.523,0.399-1.063,0.776-1.525,1.24c-0.236,0.225-0.483,0.44-0.707,0.676l-0.645,0.733c-0.106,0.123-0.215,0.244-0.318,0.369
                    l-0.285,0.396l-0.564,0.795c-0.167,0.278-0.326,0.562-0.487,0.843l-0.239,0.423c-0.076,0.143-0.136,0.294-0.205,0.441
                    l-0.394,0.887c-0.116,0.302-0.211,0.611-0.315,0.916l-0.151,0.46c-0.049,0.154-0.077,0.313-0.116,0.469l-0.214,0.942
                    c-0.055,0.317-0.089,0.637-0.132,0.956l-0.06,0.478c-0.016,0.16-0.015,0.321-0.023,0.481l-0.03,0.962
                    c0.01,0.321,0.035,0.641,0.054,0.961l0.032,0.48c0.019,0.159,0.047,0.317,0.071,0.476l0.154,0.95l0.239,0.933l0.123,0.465
                    l0.163,0.453l0.334,0.903l0.416,0.869l0.211,0.433l0.249,0.413l0.505,0.822c0.754,1.04,1.573,2.042,2.568,2.864
                    c0.469,0.443,1.01,0.8,1.536,1.175c0.129,0.097,0.268,0.18,0.41,0.259l0.422,0.241c0.285,0.154,0.556,0.335,0.858,0.456
                    l0.89,0.396c0.147,0.071,0.302,0.12,0.456,0.171l0.463,0.154c0.611,0.228,1.256,0.342,1.891,0.49
                    c2.569,0.447,5.286,0.252,7.776-0.715c1.645-0.623,3.111-1.556,4.343-2.677c0.297-0.29,0.601-0.583,0.881-0.869
                    c0.249-0.273,0.498-0.546,0.745-0.818c0.496-0.544,0.989-1.085,1.481-1.625c1.973-2.155,3.952-4.268,6.137-6.335
                    c0.097-0.091,0.146-0.19,0.126-0.231C72.18,45.52,72.084,45.555,71.982,45.662z"/>
                        </g>
                        <g>
                            <path d="M73.158,46.838c-2.19,2.057-4.192,4.157-6.198,6.306c-0.501,0.538-1.003,1.078-1.509,1.621
                    c-0.253,0.272-0.506,0.544-0.761,0.818c-0.317,0.313-0.656,0.635-0.99,0.951c-1.385,1.224-3.032,2.234-4.871,2.895
                    c-1.809,0.651-3.673,0.942-5.496,0.92c-1.825-0.016-3.612-0.372-5.284-1.01c-1.673-0.636-3.234-1.557-4.615-2.721
                    c-0.332-0.305-0.666-0.611-1.001-0.918c-0.309-0.333-0.612-0.676-0.916-1.019c-0.158-0.167-0.292-0.355-0.428-0.542
                    c-0.136-0.187-0.273-0.375-0.409-0.563c-0.286-0.368-0.508-0.784-0.749-1.19c-1.93-3.37-2.475-7.308-1.736-10.903
                    c0.372-1.8,1.072-3.515,2.029-5.054c0.96-1.54,2.172-2.896,3.571-4.025c2.829-2.238,6.439-3.457,10.09-3.373
                    c1.824,0.04,3.665,0.389,5.404,1.085c1.739,0.689,3.378,1.706,4.798,3.003c0.116,0.105,0.307,0.274,0.511,0.469
                    c0.179,0.18,0.398,0.401,0.656,0.661c0.515,0.522,1.183,1.199,1.988,2.014c1.605,1.632,3.751,3.814,6.289,6.394l-4.481,1.723
                    l0-0.001l0.354-5.92l4.127,4.197c2.366,2.406,5.072,5.158,7.997,8.132c0.183,0.186,0.367,0.373,0.551,0.56l0.277,0.282
                    l0.139,0.141l0.119,0.108c0.323,0.279,0.614,0.605,0.977,0.844c0.173,0.131,0.347,0.262,0.522,0.393
                    c0.187,0.112,0.375,0.225,0.564,0.338l0.282,0.173l0.3,0.141c0.2,0.096,0.401,0.192,0.602,0.289
                    c1.654,0.66,3.536,0.972,5.394,0.69c0.469-0.039,0.92-0.175,1.382-0.275c0.228-0.061,0.447-0.151,0.672-0.223l0.337-0.112
                    c0.109-0.046,0.215-0.099,0.323-0.147c0.215-0.1,0.434-0.193,0.648-0.296l0.617-0.36c0.213-0.109,0.403-0.255,0.596-0.397
                    c0.19-0.146,0.397-0.269,0.577-0.429c0.352-0.327,0.736-0.623,1.044-0.998l0.245-0.266c0.083-0.088,0.167-0.175,0.234-0.276
                    l0.432-0.58c0.527-0.802,1.011-1.649,1.294-2.573l0.123-0.34l0.086-0.351c0.053-0.235,0.128-0.465,0.169-0.703l0.107-0.715
                    l0.028-0.178c0.005-0.06,0.006-0.12,0.009-0.18l0.018-0.36c0.051-0.48-0.004-0.96-0.013-1.439
                    c-0.026-0.238-0.067-0.474-0.097-0.711c-0.017-0.118-0.029-0.237-0.05-0.355l-0.085-0.347c-0.185-0.937-0.564-1.813-0.989-2.645
                    c-0.118-0.202-0.237-0.404-0.355-0.605l-0.088-0.151l-0.104-0.14l-0.207-0.281l-0.204-0.282l-0.101-0.141l-0.118-0.128
                    c-0.155-0.172-0.31-0.344-0.464-0.516c-0.144-0.182-0.331-0.32-0.497-0.478c-0.173-0.15-0.332-0.317-0.512-0.455
                    c-0.183-0.135-0.366-0.269-0.548-0.402c-0.092-0.065-0.177-0.141-0.274-0.198l-0.291-0.17c-0.193-0.114-0.385-0.228-0.576-0.342
                    c-0.202-0.093-0.403-0.186-0.603-0.278l-0.299-0.141l-0.311-0.108c-0.206-0.073-0.412-0.146-0.617-0.218
                    c-1.67-0.489-3.394-0.543-4.966-0.257c-1.575,0.297-2.978,0.958-4.125,1.79c-0.282,0.215-0.574,0.409-0.814,0.657
                    c-0.126,0.116-0.252,0.231-0.376,0.346c-0.065,0.062-0.118,0.102-0.193,0.18l-0.213,0.217c-0.564,0.577-1.106,1.131-1.623,1.661
                    c-4.132,4.243-6.652,6.962-6.562,7.194c0.022,0.064,0.688-0.531,1.684-1.466c0.996-0.935,2.32-2.21,3.66-3.509
                    c1.331-1.294,2.682-2.607,3.732-3.627c0.212-0.181,0.406-0.351,0.58-0.507c0.083-0.082,0.174-0.144,0.26-0.202
                    c0.084-0.059,0.163-0.114,0.235-0.165c0.144-0.102,0.263-0.187,0.353-0.252c0.089-0.065,0.162-0.091,0.191-0.109
                    c0.908-0.51,2.188-1.013,3.761-1.17c0.781-0.104,1.637-0.062,2.518,0.068c0.887,0.117,1.788,0.43,2.663,0.853
                    c1.757,0.828,3.371,2.424,4.286,4.418c0.457,0.999,0.737,2.111,0.795,3.255c0.06,1.144-0.103,2.316-0.489,3.415
                    c-0.385,1.1-0.991,2.122-1.785,2.996c-0.795,0.878-1.761,1.594-2.82,2.08c-1.059,0.49-2.218,0.758-3.375,0.793
                    c-1.157,0.036-2.307-0.162-3.353-0.563c-1.048-0.4-1.988-0.999-2.78-1.742l-0.301-0.272l-0.422-0.429
                    c-0.281-0.286-0.56-0.57-0.838-0.853c-0.556-0.565-1.105-1.123-1.646-1.673c-2.169-2.196-4.215-4.268-6.077-6.154l-6.885-6.972
                    l-0.549,9.83v0.001l7.434-2.858c-2.216-2.224-4.169-4.185-5.772-5.794c-0.806-0.8-1.525-1.512-2.144-2.126
                    c-0.311-0.305-0.598-0.586-0.858-0.841c-0.065-0.064-0.129-0.125-0.191-0.186l-0.093-0.09c-0.042-0.038-0.083-0.075-0.123-0.111
                    c-0.163-0.145-0.317-0.282-0.461-0.41c-1.158-1.063-2.497-1.905-3.878-2.596c-0.353-0.158-0.706-0.315-1.058-0.472
                    c-0.353-0.155-0.724-0.259-1.086-0.386c-0.721-0.266-1.475-0.401-2.214-0.572c-2.989-0.514-6.061-0.283-8.835,0.753l-0.519,0.192
                    l-0.259,0.097l-0.251,0.116l-0.995,0.478l-0.247,0.122l-0.237,0.14l-0.471,0.284l-0.468,0.288
                    c-0.157,0.094-0.311,0.193-0.454,0.306c-0.583,0.44-1.18,0.862-1.695,1.373c-0.263,0.249-0.536,0.488-0.784,0.749l-0.718,0.811
                    c-0.118,0.136-0.238,0.271-0.352,0.41l-0.319,0.439l-0.626,0.883c-0.187,0.308-0.365,0.623-0.544,0.935l-0.265,0.471
                    c-0.084,0.159-0.153,0.326-0.229,0.489l-0.438,0.986c-0.129,0.335-0.237,0.678-0.353,1.017l-0.168,0.511
                    c-0.054,0.171-0.087,0.347-0.131,0.521l-0.238,1.047c-0.062,0.352-0.102,0.707-0.149,1.061l-0.066,0.531
                    c-0.018,0.177-0.018,0.356-0.027,0.534l-0.034,1.069c0.01,0.356,0.036,0.712,0.057,1.068l0.036,0.533
                    c0.021,0.177,0.051,0.353,0.077,0.529l0.17,1.055c0.082,0.347,0.173,0.692,0.262,1.038l0.138,0.517l0.179,0.505l0.371,1.005
                    l0.461,0.969l0.236,0.482l0.275,0.461l0.562,0.915c0.834,1.161,1.747,2.272,2.849,3.19c0.522,0.49,1.121,0.893,1.708,1.309
                    c0.144,0.108,0.299,0.201,0.456,0.289l0.469,0.27c0.317,0.173,0.62,0.372,0.955,0.509c0.33,0.147,0.66,0.295,0.991,0.442
                    c0.164,0.078,0.336,0.134,0.508,0.192l0.515,0.173c0.681,0.253,1.398,0.386,2.106,0.549c2.86,0.505,5.885,0.291,8.665-0.781
                    c1.836-0.692,3.477-1.728,4.856-2.974c0.331-0.318,0.677-0.651,0.982-0.961c0.249-0.273,0.498-0.546,0.745-0.818
                    c0.496-0.544,0.989-1.085,1.481-1.625c1.973-2.155,3.952-4.268,6.137-6.335c0.097-0.091,0.146-0.19,0.126-0.231
                    C73.355,46.696,73.26,46.731,73.158,46.838z"/>
                        </g>
                    </g>
                </g>
            </svg>
        </>
    );
}

export default Logo